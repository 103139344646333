import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  ReactFlowInstance,
  ReactFlowProvider,
  Viewport,
} from 'reactflow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import LoadingContainer from '@entities/Loader/LoadingContainer'
import { type Workflow, useGetTracingUrlListQuery } from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import ActionBar from '@shared/ui/ActionBar'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { LoadingButton } from '@shared/ui/buttons'
import { ConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import PrimaryGreyInput from '@shared/ui/inputs/PrimaryGreyInput'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import {
  createInitialWorkflow,
  setSubscriptionNodeId,
  validateNodes,
} from '@widgets/workflow/_shared/utils'
import { getFormTriggerNodeType } from '@widgets/workflow/_shared/utils'

import { EDGE_TYPE_COMPONENT_MAP, NODE_TYPE_COMPONENT_MAP } from './constants'
import ConversionConfiguration from './ConversionConfiguration'
import DragMenu from './DragMenu'
import { useWorkflow } from './hooks'
import { CheckboxOuter, WorkflowDisableHint, Wrapper } from './styles'
import WorkflowEndAt from './WorkflowEndAt'
import {
  useWorkflowFormState,
  useWorkflowSetFormState,
} from './WorkflowFormStateContext'

import 'reactflow/dist/style.css'

const REACT_FLOW_DEFAULT_VIEWPORT: Viewport = { x: 240, y: 60, zoom: 1 }
const REACT_FLOW_CONTROL_BUTTON_FONT_SIZE = 14
const ENTER_WORKFLOW_MIN_VALUE = 1

type Props = {
  name?: string
  initFormValues?: Workflow
  isLoading: boolean
  isSubmitting: boolean
  isSubmittingDraft: boolean
  onSubmit: (formValues: Workflow) => void
  onSubmitDraft: (formValues: Workflow) => void
}

const WorkflowForm = ({
  name = '',
  initFormValues,
  isLoading,
  isSubmitting,
  isSubmittingDraft,
  onSubmit,
  onSubmitDraft,
}: Props) => {
  const { t } = useTranslation(['common', 'workflow'])

  const isEnableSaving = useAppSelector(
    state => state.auth.plan.workflow.enableWorkflow
  )

  const [formValues, setFormValues] = useState(createInitialWorkflow())
  const formState = useWorkflowFormState()
  const setFormState = useWorkflowSetFormState()

  // 如果起始狀態非 draft，代表曾經啟用過，只能在 enabled 與 disabled 間切換
  const hasBeenEnabled =
    initFormValues !== undefined && initFormValues.status !== 'draft'

  useEffect(
    () =>
      setFormState(prevState => ({
        ...prevState,
        status: formValues.status,
        // 沒有啟用記錄才可以編輯
        isEditable: hasBeenEnabled === false,
      })),
    [hasBeenEnabled, formValues, setFormState]
  )

  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>()

  const {
    onConnect,
    onEdgesChange,
    onLayout,
    onNodesChange,
    onDragOver,
    onDrop,
  } = useWorkflow(
    initFormValues,
    setFormValues,
    reactFlowWrapper,
    reactFlowInstance
  )

  const onInit = (reactFlowInstance: ReactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance)
  }

  const [isOpenSettingDialog, setIsOpenSettingDialog] = useState(false)

  const handleFormValuesChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [target.name]:
        target.type === 'checkbox'
          ? target.checked
          : target.type === 'number'
          ? Math.max(+target.value, +target.min)
          : target.value,
    }))
  }

  const dispatch = useAppDispatch()

  const handleSubmitDraft = () => {
    // 更新訂閱名單的流水號
    const updatedNodes = setSubscriptionNodeId(formValues.nodes)

    onSubmitDraft({
      ...formValues,
      nodes: updatedNodes,
    })
  }

  const [isValidating, setIsValidating] = useState(false)

  const { data: tracingUrls } = useGetTracingUrlListQuery()

  const handleSubmit = async () => {
    setFormState(prev => ({ ...prev, isSubmitted: true }))

    // 暫時沒有方法從 nodes 取得來源 handles 做驗證，使用 selector 撈出對應的元素
    const sourceHandles = Array.from(
      document.querySelectorAll<HTMLDivElement>('.react-flow__handle.source')
    ).map(elem => ({
      nodeId: elem.dataset['nodeid'] ?? '',
      handleId: elem.dataset['handleid'] ?? null,
    }))

    setIsValidating(true)

    const validateError = await validateNodes(
      formValues.nodes,
      formValues.edges,
      sourceHandles,
      tracingUrls
    )

    setIsValidating(false)

    if (validateError) {
      dispatch(
        openToast({
          message: t(`workflow:${validateError}`) as string,
          status: 'error',
        })
      )
      return
    }

    // 更新訂閱名單的流水號
    const updatedNodes = setSubscriptionNodeId(formValues.nodes)

    onSubmit({ ...formValues, nodes: updatedNodes })
  }

  const isNodeEditable = isLoading === false && formState.isEditable

  const audienceType = useMemo(() => {
    const triggerNodeType = getFormTriggerNodeType(formValues?.nodes ?? [])

    if (!triggerNodeType) {
      return ''
    }

    if (triggerNodeType === 'trigger-enter_audience_rule') {
      return 'dynamic'
    }

    if (triggerNodeType === 'trigger-current_audience_rule') {
      return 'static'
    }
  }, [formValues?.nodes])

  useEffect(() => {
    if (audienceType !== 'dynamic' && Boolean(audienceType)) {
      setFormValues(perv => ({
        ...perv,
        endAt: undefined,
      }))
    }
  }, [audienceType])

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Breadcrumbs>
          <Link to={`/${CATEGORY.ma}/${PAGE_ROOT.workflows}`}>
            {t('common:route.workflow')}
          </Link>
          {!isLoading && (
            <Box>
              {name ? (
                <Tooltip title={name}>
                  <Box>{name}</Box>
                </Tooltip>
              ) : (
                t('common:create')
              )}
            </Box>
          )}
        </Breadcrumbs>

        <ActionBar>
          <Button
            disabled={isLoading}
            onClick={() => setIsOpenSettingDialog(true)}
            startIcon={<i className={ICON.setting} />}
            color="inherit"
            variant="contained"
          >
            {t('workflow:advanced_options')}
          </Button>

          {formValues?.status === 'draft' && (
            <LoadingButton
              disabled={isLoading || isSubmittingDraft}
              onClick={handleSubmitDraft}
              icon={ICON.editAlt}
              color="inherit"
              variant="contained"
              isLoading={isSubmittingDraft}
            >
              {t('workflow:save_draft')}
            </LoadingButton>
          )}

          {isEnableSaving && (
            <LoadingButton
              disabled={isLoading || isValidating || isSubmitting}
              icon={ICON.save}
              isLoading={isValidating || isSubmitting}
              onClick={handleSubmit}
            >
              {t('common:save')}
            </LoadingButton>
          )}
        </ActionBar>
      </Box>

      <LoadingContainer isLoading={isLoading}>
        {!isLoading && !isNodeEditable && (
          <WorkflowDisableHint>
            {t('workflow:edit_enabled_workflow_hint')}
          </WorkflowDisableHint>
        )}

        <Box display="flex">
          <ReactFlowProvider>
            <DragMenu />
            <Wrapper ref={reactFlowWrapper}>
              <ReactFlow
                defaultMarkerColor={theme.colors.blackTwo}
                defaultViewport={REACT_FLOW_DEFAULT_VIEWPORT}
                edges={
                  isNodeEditable
                    ? formValues.edges.map(edge => ({
                        ...edge,
                        type: 'edgeWithDelete',
                      }))
                    : formValues.edges
                }
                edgeTypes={EDGE_TYPE_COMPONENT_MAP}
                elementsSelectable={isNodeEditable}
                nodes={formValues.nodes}
                nodesConnectable={isNodeEditable}
                nodesDraggable={isNodeEditable}
                nodeTypes={NODE_TYPE_COMPONENT_MAP}
                onConnect={onConnect}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onEdgesChange={onEdgesChange}
                onInit={onInit}
                onNodesChange={onNodesChange}
                style={{ background: theme.colors.white }}
              >
                <Controls showInteractive={false}>
                  <ControlButton onClick={() => onLayout()}>
                    <Box fontSize={REACT_FLOW_CONTROL_BUTTON_FONT_SIZE}>
                      <Icon icon={ICON.apps} fontSize="inherit" />
                    </Box>
                  </ControlButton>
                </Controls>

                <Background />
              </ReactFlow>
            </Wrapper>
          </ReactFlowProvider>
        </Box>
      </LoadingContainer>

      <ConfirmDialog
        hasCancelBtn={false}
        isOpen={isOpenSettingDialog}
        contentOverflowY="visible"
        modalTitle={t('workflow:advanced_options')}
        onConfirm={() => {
          setIsOpenSettingDialog(false)
        }}
      >
        <Box
          fontWeight={400}
          fontSize={14}
          lineHeight="30px"
          color={theme.colors.textSecondBlue}
        >
          {t('workflow:advanced_option_setting.entry_setting')}
        </Box>
        <CheckboxOuter>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.sameUserRunLimitEnabled}
                color="primary"
                name="sameUserRunLimitEnabled"
                onChange={handleFormValuesChange}
              />
            }
            label={
              <Trans
                ns="workflow"
                i18nKey="advanced_options_limit_duration"
                components={{
                  Strong: (
                    <Box
                      component="span"
                      sx={{ fontSize: 14 }}
                      fontWeight={600}
                      color={theme.colors.azul}
                    />
                  ),
                  Label: (
                    <Box
                      component="span"
                      sx={{ fontSize: 14 }}
                      fontWeight={600}
                    />
                  ),
                }}
              />
            }
          />

          {formValues.sameUserRunLimitEnabled && (
            <PrimaryGreyInput
              min={`${ENTER_WORKFLOW_MIN_VALUE}`}
              name="sameUserRunLimit"
              onChange={handleFormValuesChange}
              type="number"
              value={`${formValues.sameUserRunLimit}`}
            />
          )}
        </CheckboxOuter>

        <CheckboxOuter>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.userLifetimeLimitEnabled}
                color="primary"
                name="userLifetimeLimitEnabled"
                onChange={handleFormValuesChange}
              />
            }
            label={
              <Trans
                ns="workflow"
                i18nKey="advanced_options_limit_total"
                components={{
                  Strong: (
                    <Box
                      component="span"
                      sx={{ fontSize: 14 }}
                      fontWeight={600}
                      color={theme.colors.azul}
                    />
                  ),
                  Label: (
                    <Box
                      component="span"
                      sx={{ fontSize: 14 }}
                      fontWeight={600}
                    />
                  ),
                }}
              />
            }
          />
          {formValues.userLifetimeLimitEnabled && (
            <PrimaryGreyInput
              min={`${ENTER_WORKFLOW_MIN_VALUE}`}
              name="userLifetimeLimit"
              onChange={handleFormValuesChange}
              type="number"
              value={`${formValues.userLifetimeLimit}`}
            />
          )}
        </CheckboxOuter>

        <Box
          fontWeight={400}
          fontSize={14}
          lineHeight="30px"
          color={theme.colors.textSecondBlue}
        >
          {t('workflow:advanced_option_setting.marketing_conversion_setting')}
        </Box>
        <CheckboxOuter>
          <ConversionConfiguration
            conversionEventEnable={formValues.conversionEventEnable}
            conversionEvent={formValues.conversionEvent}
            conversionValue={formValues.conversionValue}
            onChange={handleFormValuesChange}
          />
        </CheckboxOuter>

        {audienceType === 'dynamic' && (
          <>
            <Box
              fontWeight={400}
              fontSize={14}
              lineHeight="30px"
              color={theme.colors.textSecondBlue}
            >
              {t('workflow:advanced_option_setting.workflow_end_date_setting')}
            </Box>

            <CheckboxOuter>
              　
              <WorkflowEndAt
                endAt={formValues?.endAt}
                onChange={endAt =>
                  setFormValues(prevFormValues => ({
                    ...prevFormValues,
                    endAt,
                  }))
                }
              />
            </CheckboxOuter>
          </>
        )}
      </ConfirmDialog>
    </>
  )
}

export default WorkflowForm
