import pipe from 'lodash/fp/pipe'

import {
  TRACING_URL_REGEX,
  URL_WITH_UTM_REGEX,
} from '@entities/mediumTemplates'
import type { TracingUrl } from '@shared/api/rtkQuery'
import { TracingUrlError } from '@widgets/workflow/_shared'

import mapUrlParams from './mapUrlParams'

const mapActionUrlToOriginLink = (html: string) =>
  html.replace(URL_WITH_UTM_REGEX, (_match, p1) => {
    const [url, query] = p1.split(':borderBetweenHostAndUtm:')

    const { host } = mapUrlParams(url, query)

    return host
  })

const mapTracingUrlToOriginLink =
  (tracingUrlList: TracingUrl[]) => (html: string) =>
    html.replace(TRACING_URL_REGEX, (_match, id: string) => {
      const tracingUrl = tracingUrlList.find(item => `${item.id}` === id)

      if (!tracingUrl) {
        throw new TracingUrlError()
      }

      return tracingUrl.url
    })

export const recoverToOriginalLink = (
  html: string,
  tracingUrlList: TracingUrl[] | undefined = []
) =>
  pipe(
    mapActionUrlToOriginLink,
    mapTracingUrlToOriginLink(tracingUrlList)
  )(html)

export const checkTracingUrlsAvailable = (
  html: string,
  tracingUrlList: TracingUrl[]
) => {
  for (const [, id] of html.matchAll(TRACING_URL_REGEX)) {
    const isAvailable = tracingUrlList.some(item => `${item.id}` === id)

    if (!isAvailable) {
      throw new TracingUrlError()
    }
  }

  return html
}

export default recoverToOriginalLink
