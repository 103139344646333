import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'

import theme from '@theme'

import BasicInput, { Props as BasicInputProps } from './BasicInput'

type Props = BasicInputProps & {
  bgColor?: string
  hasShadow?: boolean
  isError?: boolean
  sx?: SxProps
}

const SearchInput = ({
  bgColor = theme.colors.white,
  defaultValue,
  hasShadow,
  isError,
  onChange,
  onSearch,
  sx,
  placeholder,
}: Props) => (
  <Box
    sx={{
      width: '100%',
      form: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        pl: 2,
        width: '100%',
        minWidth: '280px',
        borderRadius: '4px',
        backgroundColor: bgColor,
        outline: theme =>
          isError ? `1px solid ${theme.colors.orangeyRed}` : 'none',
        boxShadow: theme =>
          hasShadow ? `0px 4px 9px ${theme.colors.black6}` : 'none',
      },
      input: {
        width: '100%',
        height: 42,
        border: 'none',
        fontSize: '13px',
        '&::placeholder': {
          color: theme.colors.textSecondBlue,
        },
        '&:focus': {
          outline: 'none',
        },
        backgroundColor: bgColor,
      },
      button: {
        width: '48px',
        height: '42px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: bgColor,
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      },
      ...sx,
    }}
  >
    <BasicInput
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
    />
  </Box>
)

export default SearchInput
