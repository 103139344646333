import { useEffect, useMemo, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { SpacingProps } from '@mui/system'

import { resetFacebookAuth, setFacebookAuth } from '@entities/auth/model/slices'
import {
  SchedulingType,
  useExportAudienceRuleUserFacebookAdsMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { BlueBorderButton, DeepBlueGradientButton } from '@shared/ui/buttons'
import { ConfirmDialog } from '@shared/ui/dialogs'
import theme from '@theme'

import {
  EXPORT_BUTTON_WIDTH,
  ExportCard,
  formatExportAudienceRuleTitle,
} from '../_shared'
import useIsValid from '../_shared/utils/useIsValid'
import FacebookAdsCreateDialog, {
  type FormValues,
} from './FacebookAdsCreateDialog'
import FacebookAdsExportList from './FacebookAdsExportList'
import { FacebookAdAccount, fetchFacebookAdsAccounts } from './utils'

type Props = {
  audienceRuleId: number
  audienceRuleTitle: string
  schedulingType: SchedulingType
  onExported?: VoidFunction
} & SpacingProps

const ExportFacebookAds = ({
  audienceRuleId,
  audienceRuleTitle,
  schedulingType,
  onExported,
  ...spacingProps
}: Props) => {
  const { t } = useTranslation(['audience', 'common'])

  const facebookAuth = useAppSelector(state => state.auth.facebook)

  const isAccessTokenValid = useIsValid(facebookAuth.expirationTime)

  const [accountList, setAccountList] = useState<FacebookAdAccount[]>([])

  const dispatch = useAppDispatch()

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)

  useEffect(() => {
    if (!facebookAuth.userId || !isAccessTokenValid) {
      return
    }

    fetchFacebookAdsAccounts(facebookAuth.userId, facebookAuth.accessToken)
      .then(res => {
        if (!res || res.length === 0) {
          dispatch(
            openToast({
              message: t('audience:export_facebook_no_ad_account'),
              status: 'error',
            })
          )

          dispatch(resetFacebookAuth())
          return
        }

        setAccountList(res)
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('audience:export_facebook_fetch_ad_account_failure'),
            status: 'error',
          })
        )

        dispatch(resetFacebookAuth())
      })
  }, [dispatch, facebookAuth, isAccessTokenValid, t])

  const [exportParams, setExportParams] = useState<
    { accountId: string; exportId?: number; listName?: string } | undefined
  >()

  const [exportFacebookAds, { isLoading: isFacebookAdsExporting }] =
    useExportAudienceRuleUserFacebookAdsMutation()

  const isExportFacebookAdsAvailable = useAppSelector(
    state => state.auth.plan.audience.exportFacebookAds
  )

  const exportName = useMemo(
    () =>
      formatExportAudienceRuleTitle({
        title: audienceRuleTitle,
        isDynamic: schedulingType === 'default',
      }),
    [audienceRuleTitle, schedulingType]
  )

  const handleExportFacebookAds = async (values: FormValues) => {
    if (!exportParams || !facebookAuth) {
      return
    }

    await exportFacebookAds({
      accountId: exportParams.accountId,
      audienceRuleId: audienceRuleId,
      facebookAccessToken: facebookAuth.accessToken,
      id: exportParams.exportId,
      name: values.name,
      updatePolicy: values.updatePolicy,
    }).unwrap()

    setExportParams(undefined)
    onExported?.()
  }

  return (
    <>
      <ExportCard
        imageURL="/images/logo-facebook.svg"
        title={t('audience:export_facebook_ads')}
        description={
          isAccessTokenValid
            ? ''
            : t('audience:export_facebook_ads_authorize_description')
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...spacingProps}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {isAccessTokenValid ? (
            <DeepBlueGradientButton
              width={EXPORT_BUTTON_WIDTH}
              disabled={!isExportFacebookAdsAvailable}
              onClick={() => setIsSettingsDialogOpen(true)}
            >
              {t('audience:export.settings')}
            </DeepBlueGradientButton>
          ) : (
            <FacebookLogin
              appId={import.meta.env.VITE_FACEBOOK_APP_ID!}
              autoLoad={false}
              version="21.0"
              fields="name,email,picture"
              onFailure={() =>
                dispatch(
                  openToast({
                    message: t(
                      'audience:export_facebook_authorize_facebook_failure'
                    ),
                    status: 'error',
                  })
                )
              }
              scope="ads_management,business_management"
              callback={res => {
                if ('id' in res) {
                  dispatch(
                    setFacebookAuth({
                      userId: res.id,
                      accessToken: res.accessToken,
                    })
                  )

                  setIsSettingsDialogOpen(true)
                }
              }}
              render={renderProps => (
                <BlueBorderButton
                  sx={{ minWidth: EXPORT_BUTTON_WIDTH, height: 42 }}
                  disabled={!isExportFacebookAdsAvailable}
                  onClick={renderProps.onClick}
                >
                  {t('audience:export_facebook_ads_authorize')}
                </BlueBorderButton>
              )}
            />
          )}

          {!isExportFacebookAdsAvailable && (
            <Box mt={1} color={theme.colors.textSecondBlue}>
              {t('common:trial.not_available_in_trial')}
            </Box>
          )}
        </Box>
      </ExportCard>

      <ConfirmDialog
        cancelText={t('common:back')}
        hasConfirmBtn={false}
        isOpen={isSettingsDialogOpen && !exportParams}
        // 使用 keepMounted 儘早透過 FacebookAdsExportList 取得匯出狀態
        keepMounted
        maxWidth="md"
        modalTitle={t('audience:export.settings')}
        onClose={() => setIsSettingsDialogOpen(false)}
      >
        <FacebookAdsExportList
          accountList={accountList}
          audienceRuleId={audienceRuleId}
          onExport={setExportParams}
        />
      </ConfirmDialog>

      {/* 避免對話框消失時，會看到 listName 的變更 */}
      {Boolean(exportParams) && (
        <FacebookAdsCreateDialog
          isOpen
          isSubmitting={isFacebookAdsExporting}
          listName={exportParams?.listName ?? exportName}
          schedulingType={schedulingType}
          onCancel={() => setExportParams(undefined)}
          onSubmit={handleExportFacebookAds}
        />
      )}
    </>
  )
}

export default ExportFacebookAds
