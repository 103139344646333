import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import MuiRadio from '@mui/material/Radio'

import {
  INITIAL_PAGINATION_RESPONSE,
  useContentTemplateListQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { SearchInput } from '@shared/ui/searchInput'
import { DataTable, TableColumn } from '@shared/ui/table'
import { Tab } from '@shared/ui/tabs'
import theme from '@theme'

import templates, { TemplateSms } from './defaultSmsTemplates'

const BodySpan = styled.div`
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Radio = styled(MuiRadio)`
  && {
    padding: 0;
  }
`

type TabMode = 'default' | 'custom'

type ImportSmsTemplateProps = {
  onChange: (template?: TemplateSms) => void
}

export const SmsTemplate = ({ onChange }: ImportSmsTemplateProps) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const [mode, setMode] = useState<TabMode>('default')
  const [selection, setSelection] = useState<TemplateSms>()

  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE)

  const [searchTitle, setSearchTitle] = useState<string>('')

  const { data = INITIAL_PAGINATION_RESPONSE, isFetching } =
    useContentTemplateListQuery({
      page,
      perPage,
      mediumTypes: ['sms'],
      title: searchTitle,
    })

  const handleSearch = (title: string) => {
    setSearchTitle(title)
    setPage(DEFAULT_PAGE)
  }

  const columns: TableColumn<TemplateSms>[] = [
    {
      field: 'id',
      headerName: '',
      colRatio: 0.5,
      valueFormatter: id => (
        <Radio color="primary" checked={selection?.id === id} />
      ),
    },
    {
      field: 'body',
      headerName: 'preview',
      valueFormatter: body => <BodySpan>{body}</BodySpan>,
    },
    {
      field: 'id',
      colRatio: 0.5,
      headerName: 'content_template_id',
    },
    {
      field: 'title',
      colRatio: 0.5,
      headerName: 'content_template_name',
    },
  ]

  const handleTabClick = (tabMode: TabMode) => {
    setMode(tabMode)
    // 由於可能當前在第 N 頁，才需要透過 effect 預選首頁第一筆資料
    setSelection(undefined)

    setPerPage(DEFAULT_PER_PAGE)
    setPage(DEFAULT_PAGE)
  }

  const handleTableRowClick = (data: TemplateSms) => {
    setSelection(data)
    onChange(data)
  }

  useEffect(() => {
    if (selection) {
      return
    }

    const [firstSelection] = mode === 'default' ? templates : data.items

    if (firstSelection) {
      setSelection(firstSelection)
      onChange(firstSelection)
    }
  }, [data.items, mode, onChange, selection])

  return (
    <div>
      <Tab
        currTab={mode}
        tabOptions={[
          {
            name: t('contentTemplate:default_content_template'),
            type: 'default',
          },
          {
            name: t('contentTemplate:custom_content_template'),
            type: 'custom',
          },
        ]}
        onTabChange={handleTabClick}
        sx={{ mb: 2 }}
      />

      {mode === 'default' && (
        <DataTable
          columns={columns}
          rows={templates.slice((page - 1) * perPage, page * perPage)}
          count={templates.length}
          page={page - 1}
          rowsPerPage={perPage}
          containerType="border"
          isRowClickable
          onRowClick={handleTableRowClick}
          onPageChange={newPage => setPage(newPage + 1)}
          onRowsPerPageChange={setPerPage}
        />
      )}

      {mode === 'custom' && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <SearchInput
              bgColor={theme.colors.veryLightGrey}
              placeholder="Search templates"
              defaultValue={searchTitle}
              onSearch={handleSearch}
              sx={{ width: 'auto' }}
            />
          </Box>

          <DataTable
            columns={columns}
            rows={data.items}
            count={data.totalCount}
            page={page - 1}
            containerType="border"
            rowsPerPage={perPage}
            isRowClickable
            isLoading={isFetching}
            onRowClick={handleTableRowClick}
            onPageChange={newPage => setPage(newPage + 1)}
            onRowsPerPageChange={setPerPage}
          />
        </>
      )}
    </div>
  )
}

export default SmsTemplate
