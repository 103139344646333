import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { formatRTKQueryError } from '@entities/apiHandler'
import type { ExportDesignFunction } from '@entities/mediumTemplates'
import { useUpdateContentTemplateMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CONTENT_TEMPLATE_ERROR_CODE } from '@shared/model/constants/contentTemplate'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { CONTENT_SETTING_PATH } from '@widgets/contentSetting/model/constants'
import SaveTemplateAndSendEmailActionBar from '@widgets/contentSetting/ui/ContentTemplate/_shared/SaveTemplateAndSendEmailActionBar'
import { TracingUrlError } from '@widgets/workflow/_shared'

import TitleEditor from './TitleEditor'

const TITLE_EDITOR_MAX_WIDTH = 420

type Props = {
  contentTemplateId: string
  onExportDesign: ExportDesignFunction
  title: string
}

const SectionHeader = ({
  contentTemplateId,
  onExportDesign,
  title: titleFromAPI,
}: Props) => {
  const [isSavingContentTemplate, setIsSavingContentTemplate] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['common', 'contentTemplate'])
  const [updateContentTemplate] = useUpdateContentTemplateMutation()
  const [title, setTitle] = useState(titleFromAPI)

  const onSave = async () => {
    if (!contentTemplateId) {
      dispatch(
        openToast({
          message: t('contentTemplate:save_failed'),
          status: 'error',
        })
      )
      return
    }

    if (!title) {
      dispatch(
        openToast({
          message: t('contentTemplate:title_is_empty'),
          status: 'error',
        })
      )
      return
    }
    setIsSavingContentTemplate(true)

    try {
      const { html, replacementData, previewUrl, design } =
        await onExportDesign()

      await updateContentTemplate({
        id: parseInt(contentTemplateId, 10),
        body: {
          title,
          sentBodyGenParams: html,
          replacementData,
          previewUrl,
          body: JSON.stringify({ editorJson: design }),
        },
      }).unwrap()
      dispatch(
        openToast({
          message: t('contentTemplate:save_succeed'),
          status: 'success',
        })
      )
    } catch (error) {
      if (error instanceof TracingUrlError) {
        dispatch(
          openToast({
            message: t('workflow:errors.tracing_url_is_archived_plz_check'),
            status: 'error',
          })
        )
        return
      }

      const typedError = formatRTKQueryError<{ errorCode: number }>(error)

      if (
        typedError.data?.errorCode ===
        CONTENT_TEMPLATE_ERROR_CODE.DUPLICATE_EMAIL_PERSONALIZED_BLOCK
      ) {
        dispatch(
          openToast({
            message: t('contentTemplate:errors.duplicate_personalized_block'),
            status: 'error',
          })
        )
        return
      }

      dispatch(
        openToast({
          message: t('contentTemplate:save_failed'),
          status: 'error',
        })
      )
    } finally {
      setIsSavingContentTemplate(false)
    }
  }

  return (
    <Breadcrumbs
      actionBar={
        <SaveTemplateAndSendEmailActionBar
          isSaving={isSavingContentTemplate}
          onSave={onSave}
          onExportDesign={onExportDesign}
        />
      }
      sx={{
        '& .MuiBreadcrumbs-li': {
          maxWidth: TITLE_EDITOR_MAX_WIDTH,
          '& .MuiBox-root': {
            maxWidth: TITLE_EDITOR_MAX_WIDTH,
          },
        },
      }}
    >
      <Link to={CONTENT_SETTING_PATH.CONTENT_TEMPLATE}>
        {t('common:route.content_setting')}
      </Link>

      <TitleEditor
        contentTemplateId={contentTemplateId}
        title={title}
        onSetTitle={setTitle}
      />
    </Breadcrumbs>
  )
}

export default SectionHeader
